import axios from "axios"
if (localStorage.getItem("userAuthEnseignant")) {
  var userAuthEnseignant = JSON.parse(localStorage.getItem("userAuthEnseignant"))
  var token = userAuthEnseignant
}
if (localStorage.getItem("anneeScolaire")) {
  var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
  var annee = anneeScolaire.Annee.id
}
export default axios.create({
  withCredentials: true,
  baseURL: `https://enseignant.backcresus-institut.ovh/api/`,
  headers: {
    Authorization: `Bearer ${token}`,
    'X-Frame-Options' : "DENY",
    annee:annee
  },
})
