import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import API from "../../api"
import APIE from "../../apie"
import Select from "react-select"
import {
  Row,
  Col,
  Label,
  Input,
  Form,
  Button,
  Progress,
  Modal,
  ModalBody,
} from "reactstrap"
import { withRouter } from "react-router"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const EtudiantMastere = props => {
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [modal, setModal] = useState(false)
  const [file, setFile] = useState(null)
  const [uploadPercent, setUploadPercent] = useState(0)
  // Data Table
  const [orders, setOrders] = useState([])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    sizePerPage: 50,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )

  const Columns = () => [
    {
      dataField: "cin",
      text: "CIN",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "etudiant",
      text: "Nom et prénom",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "specialite",
      text: "Spécialité",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "num_dossier",
      text: "N° dossier",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "score",
      text: "Score",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const res = await API.get("etudiant/mastere/list").then(res => {
      setOrders(res.data.EtudiantMastere)
    })
    const resSpec = await API.post("specialite/get_by_niveau", {
      niveau_id: 1,
      cycle_id: 2,
    }).then(resSpec => {
      setSpecialite(resSpec.data.specialite)
    })
  }, [])

  const toggle = () => {
   if (selectSpecialite == "") {
      toast.error("⛔ Veuillez choisir une spécialité", {
        containerId: "A",
      })
    } else{
      setModal(!modal)
      setUploadPercent(0)
    }
  }
  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }
  const uploadFile = async () => {
    let data = new FormData()
    data.append("file", file)
    data.append("specialite_id", selectSpecialite.value)
    const options = {
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent
        let percent = Math.round((loaded * 100) / total)
        console.log(`${loaded}kb of ${total}kb | ${percent}%`)
        if (percent < 100) {
          setUploadPercent(percent)
        }
      },
    }
    API.post("etudiant/mastere/import", data, options).then(res => {
      setUploadPercent(100)
      API.get("etudiant/mastere/list").then(res => {
        setModal(!modal)
        setOrders(res.data.EtudiantMastere)
      })
    })
  }
  return (
    <React.Fragment>
      <div>
        <Form>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Spécialité :</Label>
                <Select
                  options={specialite}
                  isSearchable={true}
                  onChange={e => setSelectSpecialite(e)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col xs="12">
            <ToolkitProvider
              keyField="id"
              data={orders}
              columns={Columns()}
              search
              bootstrap4
            >
              {toolkitProps => (
                <div>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="success"
                          className="btn  mb-2 me-2"
                          onClick={toggle}
                        >
                          <i className="fas fa-file-import me-1" />
                          Import
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    noDataIndication={() => <NoDataIndication />}
                    striped={false}
                    bordered={false}
                    classes={"table align-middle table-nowrap"}
                    headerWrapperClasses={"table-light"}
                    hover
                    pagination={paginationFactory(pageOptions)}
                    {...toolkitProps.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </Col>
        </Row>
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "20px",
            color: "#556ee6",
          }}
          toggle={toggle}
        >
          Importation Liste Etudiants
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            {uploadPercent <= 0 ? (
              <Input
                onChange={e => addFile(e)}
                className="form-control mt-4"
                type="file"
                id="formFile"
              />
            ) : null}
            {uploadPercent > 0 && uploadPercent < 100 ? (
              <Progress
                striped
                className="progress-xl"
                color="success"
                value={uploadPercent}
              >
                {uploadPercent}%
              </Progress>
            ) : null}
            {uploadPercent == 100 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                Success
                <i
                  style={{
                    paddingLeft: "5px",
                    color: "darkcyan",
                    paddingTop: "3.5px",
                  }}
                  className="fas fa-check me-1"
                />
              </div>
            ) : null}
            {uploadPercent != 100 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="button"
                  color="success"
                  className="btn mb-2 me-2 mt-4"
                  onClick={uploadFile}
                >
                  <i className="fas fa-cloud-upload-alt me-1" />
                  Upload
                </Button>
              </div>
            ) : null}
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(EtudiantMastere)
EtudiantMastere.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
}
