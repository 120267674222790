import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody,
  Label,
  Input,
  Button,
  Form,
  ModalHeader,
} from "reactstrap"
import { withRouter } from "react-router-dom"
import DataTable from "react-data-table-component"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Switch from "react-switch"
import APIE from "../../apie"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
const DataTableEtudiant = props => {
  const [loading, setLoading] = useState(false)
  const [nbrTotlEl, setNbrTotlEl] = useState(0)
  const [nbrEltperPage, setNbrEltperPage] = useState(40)
  const [filterElement, setFilterElement] = useState("")
  const [mycurrentPage, setMycurrentPage] = useState(1)
  const [resetFirstPage, setResetFirstPage] = useState(false)
  const [data, setData] = useState([])
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const [modal1, setModal1] = useState(false)
  const [etudiantId, setEtudiantId] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [modalUpdate, setModalUpdate] = useState(false);
  const [groupe, setGroupe] = useState([]);
  const [selectGroupe, setSelectGroupe] = useState("");
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [niveau, setNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState("")
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [etudiant, setEtudiant] = useState("");
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [selectAction, setSelectAction] = useState("")
  const [arraySelect, setArraySelect] = useState([])
  const [arraySelectAll, setArraySelectAll] = useState([])
  const { SearchBar } = Search
  const Columns = () => [
    {
      dataField: "cin",
      text: "CIN",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "nom",
      text: "Nom et prénom",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "group",
      text: "Groupe",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "mobile",
      text: "Téléphone",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "email",
      text: "E-mail",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Consultation",
      isDummyField: true,
      text: "Consultation",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={"/DetailEtudiant?id=" + row.id}>
              <i
                  style={{ color: "darkcyan", cursor: "pointer" }}
                  className="fas fa-eye"
              ></i>
            </Link>
          </div>
      ),
    },
    {
      dataField: "Modification Groupe",
      isDummyField: true,
      text: "Modification Groupe",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          <div
              onClick={() => toggleupdate(row)}
              style={{ display: "flex", justifyContent: "center" }}
          >
            <i
                style={{ color: "#2a3042", cursor: "pointer" }}
                className="fas fa-user-edit"
            ></i>
          </div>
      ),
    },
    {
      dataField: "Compte",
      isDummyField: true,
      text: "Compte",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
          row.is_compte == -1 ? (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i style={{color: "blueviolet", cursor: "pointer"}}
                   className="fas fa-user-plus"
                   onClick={() => addAccount(row)}
                ></i>
              </div>
          ) : row.is_compte == 1 && row.active_compte == 0 ? (
              <div
                  onClick={() => toggle1(row)}
                  style={{ display: "flex", justifyContent: "center" }}
              >
                <i
                    style={{ color: "red", cursor: "pointer" }}
                    className="fas fa-user"
                ></i>
              </div>
          ) : (
              <div
                  onClick={() => toggle1(row)}
                  style={{ display: "flex", justifyContent: "center" }}
              >
                <i
                    style={{ color: "#34c38f", cursor: "pointer" }}
                    className="fas fa-user"
                ></i>
              </div>
          ),
    },
    {
      dataField: "Suppression",
      isDummyField: true,
      text: "Suppression",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
          row.etat != 0  ? (
              <div style={{display: "flex", justifyContent: "center"}}>
                <i style={{ color: "#ED6464", cursor: "pointer" }}
                   onClick={() => toggle(row.id)}
                   className="fas fa-trash-alt"
                ></i>
              </div>
          ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                    style={{ color: "#A7A3A3", cursor: "pointer" }}
                    className="fas fa-trash-alt"
                    title="Cet etudiant déja utilisé"
                ></i>
              </div>
          ),
    },
    {
      dataField: "active",
      isDummyField: true,
      text: "Etat",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (colIndex, row) => (
          <Switch
              uncheckedIcon={<Offsymbol />}
              checkedIcon={<OnSymbol />}
              className="me-1 mb-sm-8 mb-2"
              onColor="#34c38f"
              onChange={() => activeEtudiant(row)}
              checked={row.active}
          />
      ),
    },
  ]
  const Offsymbol = () => {
    return (
      <div
        title="Activé"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        A
      </div>
    )
  }

  const OnSymbol = props => {
    return (
      <div
        title="Désactivé"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        D
      </div>
    )
  }

  const toggle = id => {
    setModal(!modal)
    setId(id)
  }

  const toggleDelete = async () => {
    const res = await API.post("etudiant/delete", {
      id: id,
    }).then(res => {
      setModal(false)
      const resE = APIE.get("user/list_id_etudiant").then(resE => {
        const res = API.post("etudiant/list", {
          filterElement: filterElement,
          nbrElParPage: 40,
          page: 1,
          comptes: resE.data.comptes,
        }).then(res => {
          setData(res.data.etudiants[0].etudiant)
          setNbrTotlEl(res.data.etudiants[0].total)
          setLoading(true)
        })
      })
    })
  }
  const [actions, setActions] = useState([
    { value: 1, label: "Générer Compte" },
    { value: 2, label: "Suppression Compte" },
  ])
  useEffect(async () => {
    const resE = await API.get("etudiant/list_etudiant").then(resE => {
      setData(resE.data.test)
      setLoading(true)
    })
  }, [])
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: data.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    sizePerPage: 30,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: data.length,
      },
    ],
  }
  const activeEtudiant = async row => {
    var active = row.active
    var etudiantId = row.id
    if (active == 0) {
      var active = 1
      const res = await API.post("etudiant/activer", {
        id: etudiantId,
        active: active,
      }).then(res => {
        toast.success(" 🔓✔️ Etudaint Activé Avec succès", {
          containerId: "A",
        })
        API.get("etudiant/list_etudiant").then(resE => {
          setData(resE.data.test)
          setLoading(true)
        })
      })
    } else if (active == 1) {
      var active = 0
      const res = await API.post("etudiant/activer", {
        id: etudiantId,
        active: active,
      }).then(res => {
        toast.success(" 🔒❌ Etudaint Désactivé Avec succès ", {
          containerId: "A",
        })
        API.get("etudiant/list_etudiant").then(resE => {
          setData(resE.data.test)
          setLoading(true)
        })
      })
    }
  }

  const addAccount = async row => {
    var etudiant = row
    const res = await API.post("etudiant/store_user_etudiant", {
      etudiants: etudiant,
    }).then(res => {
      toast.success("Compte Ajouté Avec succès ", {
        containerId: "A",
      })
      API.get("etudiant/list_etudiant").then(resE => {
        setData(resE.data.test)
        setLoading(true)
      })
    })
  }

  const toggle2 = async row => {
    setModal1(!modal1)
  }

  const toggle1 = async row => {
    setModal1(!modal1)
    setName(row.nom)
    setEmail(row.email)
    setEtudiantId(row)
  }
  const toggleupdate = async row => {
    setModalUpdate(!modalUpdate)
    setSelectCycle("")
    setSelectNiveau("")
    setSelectSpecialite("")
    setSelectGroupe("")
    setEtudiant(row)
    const resC = await API.get("cycle/select").then(resC => {
      setCycle(resC.data.Cycle)
    })

  }
  const edit = async etudiantId => {
    var id = etudiantId.id
    const res = await APIE.post("user/update", {
      id: id,
      email: email,
      password: password,
    }).then(res => {
      setModal1(false)
    })
  }
  const editGroupe = async etudiant => {
    if (localStorage.getItem("userAuthScolarite")) {
      var userAuthScolarite = JSON.parse(
          localStorage.getItem("userAuthScolarite")
      )
      var user_id = userAuthScolarite.user.id
    }
    const res = await API.post("etudiant/update_group", {
      etudiant_id: etudiant.id,
      semestre_id: selectSemestre.value,
      group_td_id: selectGroupe.value,
      user_id:user_id
    }).then(res => {
      setModalUpdate(false)
    })
  }

  const activate = async etudiantId => {
    var id = etudiantId.id
    var active = etudiantId.active_compte
    if (active == 0) {
      var active = 1
      const res = await APIE.post("user/activate", {
        id: id,
        active: active,
      }).then(res => {
        setModal1(false)
        toast.success(" 🔓✔️ Compte Activé Avec succès", {
          containerId: "A",
        })
        API.get("etudiant/list_etudiant").then(resE => {
          setData(resE.data.test)
          setLoading(true)
        })
      })
    } else if (active == 1) {
      var active = 0
      const res = await APIE.post("user/activate", {
        id: id,
        active: active,
      }).then(res => {
        setModal1(false)
        toast.success(" 🔒❌ Compte Désactivé Avec succès", {
          containerId: "A",
        })
        API.get("etudiant/list_etudiant").then(resE => {
          setData(resE.data.test)
          setLoading(true)
        })
      })
    }
  }
  const getNiveau = async event => {
    var cycle_id = event.value
    setSelectCycle(event)
    setSelectNiveau("")
    setSelectSpecialite("")
    setSelectGroupe("")
    if (cycle_id == 1) {
      const res = await API.get("niveau/select").then(res => {
        setNiveau(res.data.Niveau)
      })
    } else {
      const res = await API.get("niveau/select_mastere").then(res => {
        setNiveau(res.data.Niveau)
      })
    }
  }
  const getSpec = async event => {
    var niveau_id = event.value
    setSelectSpecialite("")
    setSelectGroupe("")
    setSelectNiveau(event)
    const res = await API.post("specialite/get_by_niveau", {
      niveau_id: niveau_id,
      cycle_id: selectCycle.value,
    }).then(res => {
      setSpecialite(res.data.specialite)
    })
  }
  const NoDataIndication = () => (
      <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const getGroupe = async event => {
    var specialite_id = event.value
    setSelectSpecialite(event)
    setSelectGroupe("")
    const res = await API.post("group/getbysp", {
      specialite_id: specialite_id,
      cycle_id: selectCycle.value,
      niveau_id: selectNiveau.value,
    }).then(res => {
      setGroupe(res.data.groups)
    })
  }
  const action = async () => {
    setLoading(true)
    if (selectAction.value == 1) {
      if (arraySelectAll.length != 0) {
        var etudiants = arraySelectAll
      } else {
        var etudiants = arraySelect
      }
      const resEtudaint = await API.post("etudiant/activer_etudiant", {
        etudiants: etudiants,
      })
      const res = await APIE.post("user/generate_accounts", {
        etudiants: etudiants,
      }).then(res => {
        toast.success(" Les Comptes Générés Avec succès ", {
          containerId: "A",
        })
        // const resN = API.post("etudiant/notify_welcome", {
        //   etudiants: etudiants,
        // })
        API.get("etudiant/list_etudiant").then(resE => {
          setData(resE.data.test)
          setLoading(true)
        })
      })
    } else if (selectAction.value == 2) {
      if (arraySelectAll.length != 0) {
        var etudiants = arraySelectAll
      } else {
        var etudiants = arraySelect
      }
      const resD = await API.post("etudiant/deletes", {
        etudiants: etudiants,
      }).then(resD => {
        // const resDA = APIE.post("user/delete_accounts", {
        //   etudiants: resD.data.etudiants,
        // }).then(resDA => {

          API.get("etudiant/list_etudiant").then(resE => {
            setData(resE.data.test)
            setLoading(true)
            setArraySelect([])
            setArraySelectAll([])

          })

        toast.success("Les Comptes Supprimés Avec succès ", {
          containerId: "A",
        })
        // })
      })
    }
  }
  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        var array = arraySelect
        array.push(row)
        setArraySelect(array)
        let tab = [...arraySelectAll]
        tab.push(row)
        setArraySelectAll(tab)
      } else {
        var array = arraySelect
        array.splice(rowIndex, 1)
        setArraySelect(array)
        if (arraySelectAll.length != 0) {
          let tab = [...arraySelectAll]
          let etudId = row.id
          for (let index = 0; index < tab.length; index++) {
            let idEtud = tab[index].id
            if (idEtud == etudId) {
              tab.splice(index, 1)
              setArraySelectAll(tab)
              break
            }
          }
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        var array = arraySelectAll
        for (let i = 0; i < data.length; i++) {
          array.push(data[i])
        }
        setArraySelectAll(array)
      } else {
        setArraySelectAll([])
      }
    },
  }
  return (
    <React.Fragment>
      <div>
        {loading ? (
            <Row className="mt-4">
              <Col xs="12">
                <ToolkitProvider
                    keyField="id"
                    data={data}
                    columns={Columns()}
                    search
                    bootstrap4
                >
                  {toolkitProps => (
                      <div>
                        <div>
                          <div className="mb-3 row" style={{ display: "flex" }}>
                            <div className="col-md-6 mb-3 row">
                              <div className="col-md-8 select-action">
                                <Select
                                    options={actions}
                                    isSearchable={true}
                                    onChange={e => setSelectAction(e)}
                                    placeholder="Actions"
                                />
                              </div>
                              <div className="col-md-4 appliquer-button">
                                <Button
                                    type="button"
                                    color="primary"
                                    className="btn btn-primary"
                                    onClick={action}
                                >
                                  Appliquer
                                </Button>
                              </div>
                            </div>
                            <div className="col-md-6 mb-3 row">
                              <div className="col-md-8 search-box-etd">
                                <div
                                    className="search-box me-2 mb-2 d-inline-block"
                                    style={{ width: "100%" }}
                                >
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <BootstrapTable
                            wrapperClasses="table-responsive"
                            noDataIndication={() => <NoDataIndication />}
                            striped={false}
                            bordered={false}
                            selectRow={selectRow}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"table-light"}
                            hover
                            pagination={paginationFactory(pageOptions)}
                            {...toolkitProps.baseProps}
                        />
                      </div>
                  )}
                </ToolkitProvider>
              </Col>
            </Row>
          // <Row>
          //   <div className="mb-2 row">
          //     <div className="col-md-4">
          //       <div className="search-box me-2 mb-2 d-inline-block">
          //         <div className="position-relative">
          //           <label htmlFor="search-bar-0" className="search-label">
          //             <input
          //               id="search-bar-0"
          //               type="text"
          //               aria-labelledby="search-bar-0-label"
          //               className="form-control "
          //               placeholder="Search"
          //               onChange={(currentRowsPerPage, currentPage) =>
          //                 filterSearch(currentRowsPerPage, currentPage)
          //               }
          //             />
          //           </label>
          //           <i className="bx bx-search-alt search-icon" />
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          //   <Col xs="12" style={{ display: "table" }}>
          //     <DataTable
          //       columns={columns}
          //       data={data}
          //       noDataComponent="Pas de Données Disponibles"
          //       customStyles={customStyles}
          //       highlightOnHover={false}
          //       pagination={true}
          //       paginationServer={true}
          //       onChangePage={(page, totalRows) => newElement(page, totalRows)}
          //       paginationTotalRows={nbrTotlEl}
          //       paginationPerPage={nbrEltperPage}
          //       paginationRowsPerPageOptions={[40, 100, 200, 400, 800]}
          //       onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
          //         perPage(currentRowsPerPage, currentPage)
          //       }
          //       paginationResetDefaultPage={resetFirstPage}
          //       paginationComponentOptions={{
          //         rowsPerPageText: "Elements par page:",
          //         rangeSeparatorText: "de",
          //         noRowsPerPage: false,
          //       }}
          //     />
          //   </Col>
          // </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader
          style={{ width: "100% !important", textAlign: "center !important" }}
          toggle={toggle}
          tag="h4"
        >
          {" Suppression etudiant"}{" "}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Voulez-vous supprimer cet etudiant ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleDelete}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalUpdate} toggle={toggleupdate} centered={true}>

        <ModalBody>
          <Row>
            <Form>
              <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Semestre</Label>
                  <Select
                      options={semestre}
                      isSearchable={true}
                      value={selectSemestre}
                      onChange={e => setSelectSemestre(e)}
                  />
                </div>
              </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Cycle : </Label>
                    <Select
                        options={cycle}
                        isSearchable={true}
                        value={selectCycle}
                        onChange={e => getNiveau(e)}
                    />
                  </div>
                </Col>

          </Row>
              <Row>

                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Niveau : </Label>
                    <Select
                        options={niveau}
                        isSearchable={true}
                        value={selectNiveau}
                        onChange={e => getSpec(e)}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Spécialité :</Label>
                    <Select
                        options={specialite}
                        isSearchable={true}
                        value={selectSpecialite}
                        onChange={e => getGroupe(e)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>

                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Groupe : </Label>
                    <Select
                        options={groupe}
                        isSearchable={true}
                        value={selectGroupe}
                        onChange={e => setSelectGroupe(e)}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}

              <di lg="4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={toggleupdate}
                  >
                    Annuler
                  </Button>
                </div>
              </di>
              <div lg="4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={() => editGroupe(etudiant)}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal1} toggle={toggle1} centered={true}>
        <ModalBody>
          <Row>
            <Form>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Email</Label>
                    <Input
                        lg="3"
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Password</Label>
                    <Input
                        lg="3"
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        onChange={e => setPassword(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
            <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
            >
              {" "}
              <div lg="4">
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    className="text-center mt-4"
                >
                  {etudiantId.active_compte == 0 ? (
                      <Button
                          type="button"
                          color="success"
                          className="btn btn-primary  mb-2 me-2"
                          onClick={() => activate(etudiantId)}
                      >
                        Activer
                      </Button>
                  ) : (
                      <Button
                          type="button"
                          color="danger"
                          className="btn btn-primary  mb-2 me-2"
                          onClick={() => activate(etudiantId)}
                      >
                        Desactiver
                      </Button>
                  )}
                </div>
              </div>
              <di lg="4">
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    className="text-center mt-4"
                >
                  <Button
                      type="button"
                      color="warning"
                      className="btn btn-warning  mb-2 me-2"
                      onClick={toggle2}
                  >
                    Annuler
                  </Button>
                </div>
              </di>
              <div lg="4">
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    className="text-center mt-4"
                >
                  <button
                      type="button"
                      className="btn btn-primary "
                      onClick={() => edit(etudiantId)}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </Row>
        </ModalBody>
      </Modal>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableEtudiant))
DataTableEtudiant.propTypes = {
  data: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
